



























































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '../../store/layout';
import ConfigurationDrawer from '~/components/configuration/ConfigurationDrawer.vue';
@Component<Header>({
  components: {
    ConfigurationDrawer,
  },
})
export default class Header extends Vue {
  @Resolve
  public layout!: LayoutModule;

  public get drawer() {
    return this.layout.drawer;
  }
  public set drawer(v) {
    this.layout.setDrawer(v);
  }

  public get headerLogoSrc() {
    return this.$settings.url(this.$settings.headerLogo);
  }


  public get brkPoints() {
    return {
      'brk-xs': this.$vuetify.breakpoint.xs,
      'brk-xs-only': this.$vuetify.breakpoint.xsOnly,
      'brk-sm': this.$vuetify.breakpoint.sm,
      'brk-sm-only': this.$vuetify.breakpoint.smOnly,
      'brk-sm-and-down': this.$vuetify.breakpoint.smAndDown,
      'brk-sm-and-up': this.$vuetify.breakpoint.smAndUp,
      'brk-md': this.$vuetify.breakpoint.md,
      'brk-md-only': this.$vuetify.breakpoint.mdOnly,
      'brk-md-and-down': this.$vuetify.breakpoint.mdAndDown,
      'brk-md-and-up': this.$vuetify.breakpoint.mdAndUp,
      'brk-lg': this.$vuetify.breakpoint.lg,
      'brk-lg-only': this.$vuetify.breakpoint.lgOnly,
      'brk-lg-and-down': this.$vuetify.breakpoint.lgAndDown,
      'brk-lg-and-up': this.$vuetify.breakpoint.lgAndUp,
      'brk-xl': this.$vuetify.breakpoint.xl,
      'brk-xl-only': this.$vuetify.breakpoint.xlOnly,
    };
  }

  handleClick() {
    this.$vuetify.goTo('#main-footer', {
      duration: 1000,
      easing: 'easeInOutCubic',
      offset: 70,
    });
  }
}
