export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogoLeft: string;
    headerLogoRight: string;
    footerLogo: string;
    insuranceCardArt: string;
    tempCardArt: string;
    tempCardArtBack: string;
    reEnrollPageCardArt: string;
    activatePageCardArt: string;
    cardTypeQuestionId: string;
    cardTypeAnswerId: string;
    programId: string;
    surveyId: string;
    eligibilityFederalQuestion: string;
    eligibilityFederalAnswer: string;

    eligibilityInsurancetypeQuestion: string;
    eligibilityInsuranceCommercialAnswer : string;

    eligibilityCitizenQuestion: string;
    eligibilityCitizenAnswer: string;

    eligibilityPersonalInfoQuestion: string;
    eligibilityPersonalInfoAnswer: string;

    eligibilityAgeQuestion: string;
    eligibilityAgeAnswer: string;

    isHACPath: string;
    isHACPathYes: string;
    isHACPathNo: string;

    pathQuestion: string;
    pathPatientWebsite: string;
    pathPatientQrCode: string;

    lockEnabled: string;
    lockPassword: string;

    patientCopayConsentId: string;
    hipaaConsentId: string;
    headerLogo: string;

    landingCardArt: string;
    fileTypeId: string;

    footerLogoLeft: string;
    footerLogoRight: string;

    age18OrOlderQuestion: string;
    age18OrOlderAnswerYes: string;
    age18OrOlderAnswerNo: string;

    prescriptionQuestion: string;
    prescriptionAnswerYes: string;
    prescriptionAnswerNo: string;

    prescriptionInsuranceQuestion: string;
    prescriptionInsuranceAnswerYes: string;
    prescriptionInsuranceAnswerNo: string;

    resourseQuestion: string;
    resourseMajorDepressiveDisorder: string;
    resourseAAD: string;
    resourseOtherCondition : string;

    agreeAuthorizedQuestion: string;
    agreeAuthorizedAnswerYes: string;
    agreeAuthorizedAnswerNo: string;
}
