// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".safety-info-header{font-family:Helvetica Neue LTStd Bd,serif;color:#144633!important;padding-top:.5rem;margin-right:1rem;font-size:22px;line-height:unset!important}@media screen and (max-width:768px){.safety-info-header{font-size:16px}}.scroll-content{overflow-y:scroll;height:280px}.isi-margin-bottom{margin-bottom:200px!important;margin-left:.5rem}.isi-margin-right{margin-right:.5rem}.desktop-padding-expanded{padding-left:107px;padding-right:107px;max-width:none}@media screen and (max-width:768px){.desktop-padding-expanded{padding-left:10px;padding-right:10px}}.desktop-padding{display:flex;margin:0 auto;max-width:1120px;padding-left:72px;padding-right:72px}@media screen and (max-width:768px){.desktop-padding{padding-left:10px;padding-right:10px}}.scroll-content::-webkit-scrollbar{width:5px;background-color:#e5e0e0}.scroll-content::-webkit-scrollbar-thumb{background-color:#90aaa4;border-radius:5px}.isi-content{background-color:#fff}.isi-content h4{color:var(--color-secondary);font-weight:700}.isi-content .iri-section{background-color:#fff;padding:0 0 1em}.isi-content .iri-section:first-of-type .inner-iri-section{border-bottom:1px solid #ababab;padding-bottom:1em}.isi-content h3{font-size:22px;font-weight:400;color:var(--color-secondary)}.isi-content h4{font-size:22px}.isi-content .highlight{font-size:1.2em;font-weight:700}.isi-content a{color:#497527!important;text-decoration:underline!important;font-weight:600;font-family:Helvetica Neue LTStd Md,serif}.border-box{border:3px solid #000;padding-bottom:10px;margin-bottom:16px;max-width:1120px;padding-top:10px;letter-spacing:.5px}@media screen and (min-width:768px){.border-box{padding-right:35px!important}}.border-box ul>li{margin-left:30px}.heading{color:var(--color-secondaryBright)!important}.isi-container{font-family:Helvetica Neue LT Std\\ 55 Roman;padding-right:10px}a{word-wrap:break-word!important}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#e6a600",
	"secondaryColor": "#074104",
	"accentColor": "#c41230"
};
module.exports = exports;
