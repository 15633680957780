











































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Resolve } from 'vue-di';
import { Prop, Watch } from 'vue-property-decorator';
import { color } from '~/services/validations';
import { Title } from '~/decorators/Title';
import LayoutModule from '~/store/layout';
import colors from '~/assets/cssColors';
import { ICSSVariables } from '~/types/componentTypes';

@Component<ColorPicker>({
    layout: 'default',
})
export default class ColorPicker extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public label!: string;

    @Prop()
    public name!: keyof ICSSVariables;

    public initialColor = '';
    public color = '';
    public menu = false;

    public modes = [
        {mode: 'hexa', name: 'Hex Code'},
        {mode: 'rgba', name: 'RGB'},
        {mode: 'hsla', name:'HSL'}
    ];
    public mode = 'hexa';

    mounted() {
        this.setInitialColor();
    }

    setInitialColor() {
        this.setColorToDom(this.name, 'light');
    }

    public get isPristine() {
        return this.color === this.initialColor;
    }

    @Watch('layout.loadedConfiguration.configurationName', { deep: true, immediate: true })
    public setInitialColors() {
        this.setInitialColor();
    }

    public get swatchStyle() {
        return { backgroundColor: this.color }
    }

    handleColorChange(shouldClear = false, lightOrDark: 'light' | 'dark' = 'light') {
        const { name, color, initialColor } = this;
        if (shouldClear){
            this.color = initialColor;
        }
        const colorToSet = (shouldClear ? initialColor : color).toUpperCase();
        this.setColorToDom(name, colorToSet, lightOrDark);
        const newColors = { ...this.layout.configuration.colors };
        newColors[Number(name)] = Number(colorToSet);
        this.layout.setConfiguration({ colors: {...newColors} });
    }

    setColorToDom(name: string, color: string, lightOrDark: 'light' | 'dark' = 'light') {
        document.documentElement.style.setProperty(`--color-${name}`, color.toUpperCase());
        this.$vuetify.theme.themes[lightOrDark][name] = color.toUpperCase();
    }
}
