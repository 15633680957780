













































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';

@Component<Footer>({})
export default class Footer extends Vue {
  @Resolve
  public layout!: LayoutModule;
  @Prop()
  public brkPoints!: object;
  @Prop({ default: false })
  public isFixed!: boolean;
  isShowDialogue = false;
  componentKey = 0;

  public get footerInfoLinkLocation() {
    return this.layout.configuration.footerInfoLinkLocation;
  }

  public get isTopOrBottomBar() {
    return ['top', 'bottom'].includes(this.footerInfoLinkLocation);
  }

  public get isLeftOrRightBar() {
    return ['left', 'right'].includes(this.footerInfoLinkLocation);
  }

  public get footerLogoLeftSrc() {
    return this.$settings.url(this.$settings.footerLogoLeft);
  }

  public get footerLogoRightSrc() {
    return this.$settings.url(this.$settings.footerLogoRight);
  }

  forceRerender() {
    this.componentKey += 1;
    this.isShowDialogue = !this.isShowDialogue;
  }
}
