import { email } from 'vuelidate/lib/validators';
import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { EdgePatientEnrollmentApi } from '@trialcard/apigateway.client/edgePatientEnrollmentApi';
import { EdgeCouponApi } from '@trialcard/apigateway.client/edgeCouponApi';
import {
    EnrollPatientRequestModel,
    EnterpriseCoordinatorAccountUpsearchRequestModel,
    UpsearchModelEnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    UpsearchModelEnterpriseCoordinatorAccountServiceModelsAccountUpsearchModel,
    EnterpriseCoordinatorPatientServiceModelsPatientUpsearchModel,
    EnterpriseCoordinatorSharedAddressUpsearchModel,
    EnterpriseCoordinatorSharedEmailAddressUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactUpsearchModel,
    EnterpriseCoordinatorSharedPhoneUpsearchModel,
    UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel,
    UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel,
    UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
    EnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel,
    PayorCoverageEligibilityRequestModel,
    EnterpriseCoordinatorSiteServiceModelsSitePointOfContactUpsearchModel,
    EnterpriseCoordinatorAccountServiceModelsAccountExternalIdModel,
    EnrollPatientAccountAttestationAttribute,
    EnrollmentResultModelResponse,
    EnrollmentRequestModel,
    EnrollmentDemographicsModel
} from '@trialcard/apigateway.models';
import { AccountExternalIdType, Gender, RoleType } from '@trialcard/enums';

import moment, { Moment } from 'moment';
import { AxiosResponse } from 'axios';
import { EdgeEnrollmentApi } from '@trialcard/apigateway.client';
import ActivationModule from './activation';
import { Settings } from '~/services/settings';
import EligibilityModule from '~/store/eligibility';
import SurveyModule from '~/store/survey';

import { rolling365 } from '~/tests/e2e/@data/shared/dates'
import { pharmacyMN } from '~/tests/e2e/@data/shared/memberNumbers'

@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {
    @Resolve
    public patientEnrollmentApi!: EdgePatientEnrollmentApi;

    @Resolve
    public enrollmentApi!: EdgeEnrollmentApi;

    @Resolve
    public edgeCouponApi!: EdgeCouponApi;

    @Resolve
    public settings!: Settings;

    @Resolve
    public survey!: SurveyModule;

    @Resolve
    public eligibility!: EligibilityModule;

    @Resolve
    public activation!: ActivationModule;

    public memberPageDialog = false;
    public activatePageDialog = false;
    public currentPhoneTypeOption = '';

    public finalPage = '';

    isCareGiver: boolean | null = null;
    userType='';

    defaultConsentPageData = {
        patientName: '' as string,
        representativeName: '' as string,
        relationshipToPatient: '' as string,
        address1: '' as string,
        address2: '' as string,
        zip: '' as string,
        city: '' as string,
        state: '' as string,
        home: '' as string,
        cell: '' as string,
        email: '' as string,
        grantPermission: false as boolean,
        certificationConsent: false as boolean,
        readTnC: false as boolean,
        phi: false as boolean,
        contactConsent: false as boolean,
        patientFirstSignature: '' as string,
        representativeFirstSignature: '' as string,
        patientSecondSignature: '' as string,
        representativeSecondSignature: '' as string,
    }

    public checkboxPatientPage = false as boolean;
    public radioPatientPage =  false as boolean;

    defaultDemographicData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: '' as any,
        gender: null as any,
        zip: '' as string
    }

    defaultPatientInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        dateOfBirth: null as Moment | null,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
        gender: null as Gender | null,
    };
    defaultInsuranceCheckboxData = {
        drug : false as boolean,
    }

    defaultCareGiverInformationData = {
        firstName: '' as string,
        lastName: '' as string,
        addresses: [] as EnterpriseCoordinatorSharedAddressUpsearchModel[],
        emailAddresses: [] as EnterpriseCoordinatorSharedEmailAddressUpsearchModel[],
        phoneNumbers: [] as EnterpriseCoordinatorSharedPhoneUpsearchModel[],
    };

    defaultPrescriberData = [
        {
            model: {
                firstName: '',
                lastName: '',
                npi: '',
                accountRelationshipIsPrimary: true,
                prescriberLicenses: [],
                externalIds: [],
                addresses: [],
                phoneNumbers: [],
                emailAddresses: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[];

    defaultSiteData = [
        {
            model: {
                accountRelationshipIsPrimary: true,
                siteRoleType: RoleType.PrescriberSite,
                pointOfContacts: [],
                description: '',
                addresses: [
                    {
                        addressOne: '',
                        addressTwo: '',
                        zip: '',
                        city: '',
                        state: '',
                    },
                ],
                contacts: [],
                emailAddresses: [],
                externalIds: [],
                phoneNumbers: [],
                globalIds: [],
            },
        },
    ] as UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[];

    defaultPayorObject =  {
            model: {
                payorId: (null as unknown) as number,
                payorType: (null as unknown) as number,
                accountRelationshipIsPrimary: true,
                payorGroup: '',
                payorName: '',
                webAddresses: [],
                addresses: [],
                phoneNumbers: [
                    {
                        phoneType: 1,
                        phoneNumber: '',
                        phonePurpose: 1,
                    },
                    {
                        phoneType: 3,
                        phoneNumber: '',
                        phonePurpose: 8,
                    },
                ],
                emailAddresses: [],
                contacts: [],
                payorBenefit: {
                    dateOfBirth: null as Moment | null,
                    policyHolderFirstName: '',
                    policyHolderLastName: '',
                    relationshipToPatient: undefined,
                    policyNumber: '',
                    planType: undefined,
                },
            },
        }

    defaultPayorData = [
    ] as UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[];

    defaultContactPreferences = [] as EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[];

    defaultCoverageAreas = [] as PayorCoverageEligibilityRequestModel[];

    defaultMemberNumber = pharmacyMN.number

    defaultExpirationDate = moment().format('MM/DD/yyyy')

    defaultAttestationObject = [] as EnrollPatientAccountAttestationAttribute[];

    defaultMemberNumbers = {
        pharmacy: pharmacyMN.number as string
    };

    public demographicInformation = {
        firstName: '',
        lastName: '',
        gender: '',
        dateOfBirth: '',
        email: '',
        mobilePhone: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        communicationTypes: 0
    } as EnrollmentDemographicsModel;
    
    demographicData = { ...this.demographicInformation };

    memberNumber = this.defaultMemberNumber;
    expirationDate = this.defaultExpirationDate;
    consentPageData = { ... this.defaultConsentPageData };
    
    patientInformationData = { ...this.defaultPatientInformationData };
    careGiverInformationData = { ...this.defaultCareGiverInformationData };
    prescriberData = [ ...this.defaultPrescriberData ];
    siteData = [ ...this.defaultSiteData ];
    payorData = [ ...this.defaultPayorData ];
    payorCoverageAreas = [...this.defaultCoverageAreas];
    savedCheckBoxState = {...this.defaultInsuranceCheckboxData};
    accountAttestationObject = [...this.defaultAttestationObject];

    pharmaMemberNumber = this.defaultMemberNumbers.pharmacy;

    contactPreferences = [...this.defaultContactPreferences];

    drugInsuranceOther = "";

    offerTypes = {} as any;

    @Mutation
    public async updateInsuranceCheckbox(value: any){
        this.savedCheckBoxState = {...value};
    }
	@Mutation
	updateMemberNumber(memberNumber: string) {
		this.memberNumber = memberNumber;
	}

	@Mutation
	setUserType(userType: string) {
		this.userType = userType;
	}

	@Mutation
	setMemberPageDialog(memberPageDialog: boolean) {
		this.memberPageDialog = memberPageDialog;
	}

	@Mutation
	setActivatePageDialog(memberPageDialog: boolean) {
		this.memberPageDialog = memberPageDialog;
	}

	@Mutation
	setFinalPage(value: string) {
		this.finalPage = value;
	}

    @Mutation
    public async updateConsentPageData(newConsentPageData: typeof EnrollmentModule.prototype.consentPageData) {
        this.consentPageData = { ...this.consentPageData, ...newConsentPageData };
    }

    @Mutation
    public async updateRadioPatientPage(value: boolean) {
        this.radioPatientPage = value;
    }

    @Mutation
    public async updateCheckboxPatientPage(value: boolean) {
        this.checkboxPatientPage = value;
    }

    @Mutation
    public async updateDemographicData(newDemographicData: typeof EnrollmentModule.prototype.demographicData) {
        Object.assign(this.demographicData, newDemographicData);
    }

    @Mutation
    public async updatePatientInformation(newPatientInformation: typeof EnrollmentModule.prototype.patientInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newPatientInformation };
    }

    @Mutation
    public async updatePrescribers(newPrescriberData: UpsearchModelEnterpriseCoordinatorPrescriberServiceModelsPrescriberUpsearchModel[]) {
        this.prescriberData = [...newPrescriberData];
    }

    @Mutation
    public async updateSites(newSites: UpsearchModelEnterpriseCoordinatorSiteServiceModelsSiteUpsearchModel[]) {
        this.siteData = [...newSites];
    }

    @Mutation
    public async updatePayors(newPayorData: UpsearchModelEnterpriseCoordinatorPayorServiceModelsPayorUpsearchModel[]) {
        this.payorData = [...newPayorData];
    }

    @Mutation
    public async updateCareGiverInformation(newCareGiverInformation: typeof EnrollmentModule.prototype.careGiverInformationData) {
        this.patientInformationData = { ...this.patientInformationData, ...newCareGiverInformation };
    }

    @Mutation
    public async updateContactPreferences(newContactPreferences: EnterpriseCoordinatorAccountServiceModelsAccountContactPreferenceModel[]) {
        this.contactPreferences = [...newContactPreferences];
    }

    @Mutation
    public async updatePayorCoverageAreas(newCoverageAreas : PayorCoverageEligibilityRequestModel[]){
        this.payorCoverageAreas = [...newCoverageAreas];
    }

    @Mutation
    public async updateAccountAttestion(newAccountAttestation : EnrollPatientAccountAttestationAttribute){
        this.accountAttestationObject.push(newAccountAttestation);
    }

    @Mutation
    public async clearAccountAttestion(){
        this.accountAttestationObject = this.defaultAttestationObject;
    }

    @Mutation
    public setMemberNumber(memberNumber: string) {
        this.memberNumber = memberNumber;
    }

    @Mutation
    public setExpirationDate(expirationDate: string) {
        this.expirationDate = expirationDate;
    }

    @Mutation
    public setIsCaregiver(isCareGiver: boolean | null) {
        this.isCareGiver = isCareGiver;
    }
    @Mutation
    setCurrentPhoneType(value: string){
        this.currentPhoneTypeOption = value;
    }


    @Mutation
    public setReEnrollMemberNumber(value: string) {
        this.memberNumber = value;
    }

    @Mutation
    public setDrugInsuranceOther(drugInsuranceOther: string) {
        this.drugInsuranceOther = drugInsuranceOther;
    }

    @Mutation
    public clearConsentPageData() {
        this.consentPageData = { ... this.defaultConsentPageData };
    }

    @Mutation
    public setPharmaMemberNumber(pharmaMemberNumber: string) {
        this.pharmaMemberNumber = pharmaMemberNumber;
    }


    @Action({ rawError: true })
    public clearStore() {
        this.clearConsentPageData();
        this.eligibility.updateEligibilityData([]);
        this.setMemberNumber(this.defaultMemberNumber);
        this.setExpirationDate(this.defaultExpirationDate);
        this.updateDemographicData(this.demographicInformation);
        this.updatePatientInformation(this.defaultPatientInformationData);
        this.updateCareGiverInformation(this.defaultCareGiverInformationData);
        this.updatePrescribers(this.defaultPrescriberData);
        this.updateSites(this.defaultSiteData);
        this.updatePayors(this.defaultPayorData);
        this.updateContactPreferences(this.defaultContactPreferences);
        this.setCurrentPhoneType('');

        this.setDrugInsuranceOther("");
        this.setReEnrollMemberNumber('');
        this.eligibility.clearStore();
        this.updateCheckboxPatientPage(false);
        this.updateRadioPatientPage(false);
        this.clearAccountAttestion();
    }

    @Action({ rawError: true })
    public clearEnrollment() {
        this.updatePatientInformation({ ...this.defaultPatientInformationData });
        this.setMemberNumber(this.defaultMemberNumber);
    }

    @Action({})
    public async submitBasicEnrollment() {
        let response = { success: false } as EnrollmentResultModelResponse;
        const request = {
            survey: this.eligibility.eligibilityData,
            demographics: this.demographicData,
        };
        try {
            response = (await this.edgeCouponApi.enrollmentBasicEnrollment(
                +this.settings.programId,
                request as EnrollmentRequestModel
            )).data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error from enrollment:',error);
        }
        return response;
    }

}
