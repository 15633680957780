import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3b0c93d7 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _72e4e5e0 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _7d35f1d8 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _3a70cadc = () => interopDefault(import('..\\pages\\success.vue' /* webpackChunkName: "pages_success" */))
const _d73338f6 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _3bdb4d38 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _3be964b9 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _3c0593bb = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _3c13ab3c = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _70c18879 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _117314be = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _3b0c93d7,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _72e4e5e0,
    name: "have-a-card"
  }, {
    path: "/rejection",
    component: _7d35f1d8,
    name: "rejection"
  }, {
    path: "/success",
    component: _3a70cadc,
    name: "success"
  }, {
    path: "/unlock",
    component: _d73338f6,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _3bdb4d38,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _3be964b9,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _3c0593bb,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _3c13ab3c,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _70c18879,
    name: "errors-500"
  }, {
    path: "/",
    component: _117314be,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
